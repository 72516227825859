import React, { useMemo } from 'react';
import ArrowTooltip from '../../../ArrowTooltip';
import { Tag, Body } from '@walmart-web/livingdesign-components';
import * as Icon from '@livingdesign/icons';
import { Box, Button, withStyles } from '@material-ui/core';
import { activity } from '../../constants';
import PropTypes from 'prop-types';
import styles from './styles';
import moment from 'moment/moment';

const ActivityTypeCard = ({
  id,
  optedIn,
  title,
  description,
  onClick,
  showPopover,
  popoverTitle,
  popoverSubTitle,
  activityCount,
  classes,
  hasFeedbackSurvey,
  showWeeklyQuestFeatureIntro
}) => {
  const showFeedbackLabel =
    id === 'weekly-quests' && hasFeedbackSurvey && activityCount === 0;

  const showTag = useMemo(() => {
    const weeklyQuestFeatureIntroCompletion =
      localStorage.getItem(`${id}-feature-intro-completed`) ?? '';
    const completionDate = moment(weeklyQuestFeatureIntroCompletion);
    const within14Days = completionDate.isValid()
      ? moment().diff(completionDate, 'days') <= 14
      : false;
    return (
      id === 'weekly-quests' && (showWeeklyQuestFeatureIntro || within14Days)
    );
  }, [id, showWeeklyQuestFeatureIntro]);

  const renderSection = () => {
    if (!optedIn) {
      return (
        <>
          <Box className={classes.shareFeedbackContainer}>
            <Body size="small">{activity.activityFreeTexts.optOutLabel}</Body>
            <Body size="small">
              {activity.activityFreeTexts.optOutSubLabel}
            </Body>
          </Box>
          {showPopover && (
            <ArrowTooltip
              title={popoverTitle}
              subTitle={popoverSubTitle}
              alignment="right"
            />
          )}
        </>
      );
    }

    if (showFeedbackLabel) {
      return (
        <Box className={classes.shareFeedbackContainer}>
          <Body weight={700} size="small">
            {activity.activityFreeTexts.share}
          </Body>
          <Body weight={700} size="small">
            {activity.activityFreeTexts.feedback}
          </Body>
        </Box>
      );
    }

    return (
      <>
        <Box className={classes.shareFeedbackContainer}>
          <Body weight={700} size="medium">
            {activityCount}
          </Body>
          <Body size="small">{activity.activityFreeTexts.available}</Body>
        </Box>
      </>
    );
  };

  return (
    <Button
      classes={{ root: classes.contentBox }}
      onClick={onClick}
      key={title}
    >
      <Box className={classes.paper} data-testid={`TEST-CARD-${title}`}>
        <Box className={classes.section}>
          {showTag && (
            <Tag variant="tertiary" color="blue" leading={<Icon.StarFill />}>
              New
            </Tag>
          )}
          <Body weight={700} size="large">
            {title}
          </Body>
          <Body size="small">{description}</Body>
        </Box>
        <Box className={classes.sectionPointsContainer}>{renderSection()}</Box>
      </Box>
    </Button>
  );
};

ActivityTypeCard.defaultProps = {
  classes: {},
  hasFeedbackSurvey: false
};

ActivityTypeCard.propTypes = {
  classes: PropTypes.object,
  hasFeedbackSurvey: PropTypes.bool
};

export default withStyles(styles)(ActivityTypeCard);
