const styles = () => ({
  sectionsComponent: {
    marginTop: '16px',
    width: '90%',
    maxWidth: '480px'
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 24
  }
});

export default styles;
