export const activityCategories = [
  {
    id: 'surveys',
    title: 'Survey Activities',
    description: 'Answer questions',
    activityPreferenceDetailName: 'ap_surveys_flag'
  },
  {
    id: 'videos',
    title: 'Video Activities',
    description: 'Record video responses',
    activityPreferenceDetailName: 'ap_video_flag'
  },
  {
    id: 'weekly-quests',
    title: 'Weekly Quests',
    description: 'Short & Fun Activities',
    activityPreferenceDetailName: undefined
  }
];

export const activity = {
  activityTypes: {
    SURVEY: 'surveys',
    VIDEO: 'videos',
    WEEKLY_QUEST: 'weekly-quests'
  },
  apiTypes: {
    QUALITATIVE: 'QUALITATIVE',
    SURVEY: 'SURVEY',
    QUANTITATIVE: 'QUANTITATIVE',
    MICRO_SURVEY: 'MICRO_SURVEY'
  },
  activityFreeTexts: {
    available: 'Available',
    points: 'points',
    beginActivity: 'Begin activity',
    noActivitiesAvailable:
      'Sorry, currently there are no activities available for you.',
    optOutLabel: 'Currently',
    optOutSubLabel: ' Opted-out',
    share: 'Share',
    feedback: 'Feedback',
    thankYou: 'Thank You!',
    completed: 'Completed!',
    shareFeedback: 'Share feedback',
    shareFeedbackText1:
      'Thank you for your active participation! We hope you enjoyed conquering your Weekly Quests.',
    shareFeedbackText2:
      "As we continue to enhance the experience, we'd love to hear from you. Your input will help shape future quests and ensure more engaging adventures ahead."
  }
};

export const activitySectionStyling = {
  background: '#FFFFFF',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: 8
};
