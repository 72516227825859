import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, withStyles } from '@material-ui/core';
import Logo from '../../../../Logo';
import styles from './styles';
import { SOURCE } from '../../../../../constants/common';
import { activity } from '../../../constants';
import analytics from '../../../../../utils/analytics';
import { PAGES } from '../../../../../constants/analytics';
import { useHistory } from 'react-router-dom';
import { Body, Caption, Tag } from '@walmart-web/livingdesign-components';

const MicroSurveyActivityCard = ({
  classes,
  imageType,
  name,
  points,
  surveyLink,
  shortId,
  isCompleted
}) => {
  const history = useHistory();

  /** Internal survey links will be having panelist hash already
   * So appending only the source & separated
   */
  const framedSurveyLink = `${surveyLink}${
    surveyLink?.includes('https') ? '' : SOURCE
  }`;

  const getWeeklyQuestLink = (link) => {
    if (link.includes('panelist_hash=')) {
      const splitByPanelist = link.split('?panelist_hash=') || [];
      const panelist = splitByPanelist[1] ?? '';

      const splitForSurvey = splitByPanelist[0].split('/');
      const surveyId = splitForSurvey[splitForSurvey.length - 1];

      return `/activities/weekly-quests/${surveyId}?panelist_hash=${panelist}`;
    }
  };

  const surveyClicked = (surveyUrl) => {
    const surveyLinkEnd = surveyUrl?.split('/');
    const panelistHash = surveyUrl?.split('?panelist_hash');

    analytics.trackClick(PAGES.ACTIVITIES, 'survey', {
      survey: surveyLinkEnd?.length
        ? surveyLinkEnd?.[surveyLinkEnd?.length - 1]
        : '',
      panelistHash: panelistHash?.length > 1 ? panelistHash?.[1] : ''
    });

    history.replace(getWeeklyQuestLink(surveyUrl));
  };

  return (
    <Button
      classes={{ root: classes.contentBox }}
      onClick={() => surveyClicked(framedSurveyLink)}
    >
      <Box className={classes.paper} data-testid={`micro-survey-card-${name}`}>
        {isCompleted && (
          <div className={classes.tagContainer}>
            <Tag variant="tertiary">{activity.activityFreeTexts.completed}</Tag>
          </div>
        )}
        <Box className={classes.imgWrapper}>
          <Logo type={isCompleted ? 'celebration' : imageType} />
        </Box>
        <Box className={classes.content}>
          <Caption>{`ID: ${shortId}`}</Caption>
          <Body weight={700} size="large" UNSAFE_className={classes.title}>
            {name}
          </Body>
          <Body size="medium" UNSAFE_className={classes.points}>
            {points + ' ' + activity.activityFreeTexts.points}
          </Body>
        </Box>
      </Box>
    </Button>
  );
};

MicroSurveyActivityCard.defaultProps = {
  classes: {}
};

MicroSurveyActivityCard.propTypes = {
  classes: PropTypes.object,
  imageType: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  surveyLink: PropTypes.string,
  shortId: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool
};

export default withStyles(styles)(MicroSurveyActivityCard);
