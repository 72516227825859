const styles = (theme) => ({
  imgWrapper: {
    height: 80,
    width: 80
  },
  points: {
    color: '#333333'
  },
  title: {
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis'
  },
  tagContainer: {
    position: 'absolute',
    float: 'right',
    right: 8,
    top: 8
  },
  contentBox: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    padding: 0,
    width: '328px'
  },
  paper: {
    display: 'flex',
    width: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px 1px #00000026',
    borderRadius: 8,
    padding: '16px',
    columnGap: '16px',
    textTransform: 'none',
    alignItems: 'center',
    textAlign: 'left'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    rowGap: '4px'
  }
});

export default styles;
