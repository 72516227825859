import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from '../../Skeleton';

import styles from './styles';
import { Grid, withStyles, Box } from '@material-ui/core';
import ActivityTypeCard from './ActivityTypeCard';
import {
  activityCategories,
  activity as activityConstants
} from '../constants';
import { countActivityType, getFeedbackSurvey } from '../utils';
import { useRouteMatch, useHistory } from 'react-router-dom';
import FeatureIntroduction from '../../FeatureIntroduction/index.tsx';

const ActivityTypeList = ({
  classes,
  activityPreferences,
  availableActivities,
  isLoading,
  isWeeklyQuestFeatureFlagEnabled,
  showWeeklyQuestFeatureIntro
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const [activePopoverCategory, setActivePopoverCategory] = useState(undefined);
  const [featureIntro, setFeatureIntro] = useState({ open: false, type: '' });
  const activityCategoriesWithOptInStatus = activityCategories
    .filter(
      (
        y //apply feature flagging for MICRO SURVEY
      ) =>
        y.id !== activityConstants.activityTypes.WEEKLY_QUEST ||
        isWeeklyQuestFeatureFlagEnabled
    )
    .map((x) => {
      // cross check with preferences on if they are opted in
      // and grab messaging around opted in/out status
      // (verbiage is dynamic from api resp)
      const activityInfo = activityPreferences?.find(
        (e) => e.detailName === x.activityPreferenceDetailName
      );
      const msgArr = activityInfo?.availableActivityInfo?.split('?');
      return {
        id: x.id,
        title: x.title,
        description: x.description,
        optedIn:
          activityInfo?.selectedList?.[0] === 'true' ||
          x.id === activityConstants.activityTypes.WEEKLY_QUEST,
        count: countActivityType(availableActivities, x.id),
        infoTitle: msgArr?.length > 0 ? msgArr[0] : '',
        infoSubTitle: msgArr?.length > 1 ? msgArr[1] : ''
      };
    });

  const hasFeedbackSurvey = Boolean(getFeedbackSurvey(availableActivities));

  const onClickRow = (category) => {
    if (category.optedIn) {
      if (category.id === 'weekly-quests' && showWeeklyQuestFeatureIntro) {
        setFeatureIntro({ open: true, type: category.id });
        return;
      }
      history.push(`${url}/${category.id}`);
    } else {
      setActivePopoverCategory(category.id);
      setTimeout(() => {
        setActivePopoverCategory(undefined);
      }, 5000);
    }
  };

  const onFeatureIntroFinish = (featureType) => {
    setFeatureIntro({ open: false, type: '' });
    history.push(`${url}/${featureType}`);
  };

  useEffect(() => {
    if (isLoading) {
      setFeatureIntro({ open: false, type: '' });
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className={classes.sectionsComponent} data-testid="skeleton">
        <Skeleton count={10} />
      </div>
    );
  } else {
    return (
      <Grid item className={classes.sectionsComponent} data-testid="cards">
        <Box
          sx={{
            display: 'block',
            '& > :not(style)': {
              m: 1
            }
          }}
          className={classes.containerBox}
        >
          {activityCategoriesWithOptInStatus.map((category) => (
            <ActivityTypeCard
              data-testid={category.title}
              key={category.title}
              id={category.id}
              optedIn={category.optedIn}
              title={category.title}
              description={category.description}
              onClick={() => onClickRow(category)}
              showPopover={activePopoverCategory === category.id}
              popoverTitle={category.infoTitle}
              popoverSubTitle={category.infoSubTitle}
              activityCount={category.count}
              hasFeedbackSurvey={hasFeedbackSurvey}
              showWeeklyQuestFeatureIntro={showWeeklyQuestFeatureIntro}
            />
          ))}
        </Box>
        <FeatureIntroduction
          featureType={featureIntro.type}
          onFinish={onFeatureIntroFinish}
          open={featureIntro.open}
        />
      </Grid>
    );
  }
};

ActivityTypeList.defaultProps = {
  classes: {}
};

ActivityTypeList.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ActivityTypeList);
