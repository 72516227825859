const styles = () => ({
  paper: {
    display: 'flex',
    width: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: '16px 16px',
    columnGap: '16px',
    textTransform: 'none'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    rowGap: '6px'
  },
  sectionPointsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  contentBox: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    padding: 0
  },
  shareFeedbackContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 0,
    width: 'max-content'
  }
});

export default styles;
