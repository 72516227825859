export const weeklyQuestFeatureIntroData = [
  {
    image: '/assets/images/survey-snacks/survey-box.png',
    title: 'Introducing Weekly Quests!',
    subtitle: 'A new way to earn even more points!'
  },
  {
    image: '/assets/images/survey-snacks/survey-message.png',
    title: 'Explore new fun activities.',
    subtitle:
      'Discover our new activity styles.\nThere is always something new!'
  },
  {
    image: '/assets/images/survey-snacks/survey-giftbox.png',
    title: 'Participate & Earn points',
    subtitle:
      'Never miss an opportunity to earn rewards.\nComplete your quests every week and get rewarded!\n\nLet’s get started!'
  }
];
