import React, { useMemo, useState } from 'react';
import styles from './styles.module.css';
import {
  Body,
  Button,
  Heading,
  Modal
} from '@walmart-web/livingdesign-components';
import PaginationDots from '../PaginationDots/index.tsx';
import clsx from 'clsx';
import { weeklyQuestFeatureIntroData } from './data.ts';
import apiCall from '../../utils/api.js';
import ApiPath from '../../constants/api';
import API from '../../constants/common.js';
import { logger } from '../../utils/logger.js';

type FeatureType = 'weekly-quests';

type Props = {
  readonly open: boolean;
  readonly featureType: FeatureType;
  readonly onFinish: (featureType: FeatureType) => void;
};

type FeatureIntroFlag = { [key in FeatureType]: string };
const featureIntroFlag: FeatureIntroFlag = {
  'weekly-quests': 'micro_survey_feature_intro_complete'
};

function FeatureIntroduction({ open, featureType, onFinish }: Props) {
  const [page, setPage] = useState(0);

  const featureIntroPages = useMemo(() => {
    if (featureType === 'weekly-quests') {
      return weeklyQuestFeatureIntroData;
    }
    return [];
  }, [featureType]);

  const updateFeatureIntro = async () => {
    try {
      localStorage.setItem(
        `${featureType}-feature-intro-completed`,
        new Date().toISOString()
      );
      await apiCall(ApiPath.metadata, API.METHOD.PATCH, [
        {
          detailName: featureIntroFlag[featureType],
          selectedList: ['true']
        }
      ]);
    } catch (error) {
      // silent failure
      logger.error('Failed to update the feature introduction flag', {
        error,
        featureType
      });
    }
  };

  const onDoneClick = () => {
    updateFeatureIntro();
    onFinish(featureType);
  };

  const onNextClick = () => {
    if (page < featureIntroPages.length - 1) {
      setPage(page + 1);
    } else {
      onDoneClick();
    }
  };

  if (featureIntroPages.length === 0) {
    return null;
  }

  return (
    <Modal
      isOpen={open}
      onClose={() => {}}
      size="medium"
      title=" "
      actions={
        <div className={styles.actionContainer}>
          <div className={styles.actionHeader}>
            <Heading size="medium" UNSAFE_className={styles.actionText}>
              {featureIntroPages[page].title}
            </Heading>
            <Body
              size="medium"
              UNSAFE_className={clsx(styles.actionSubtext, styles.actionText)}
            >
              {featureIntroPages[page].subtitle}
            </Body>
          </div>
          <div className={styles.actionFooter}>
            <Button variant="tertiary" size="medium" onClick={onDoneClick}>
              Skip
            </Button>
            <PaginationDots
              totalPages={featureIntroPages.length}
              currentPage={page}
            />
            <Button variant="primary" size="medium" onClick={onNextClick}>
              {page === featureIntroPages.length - 1 ? 'Done' : 'Next'}
            </Button>
          </div>
        </div>
      }
    >
      <div className={styles.mainContainer}>
        <img
          className={styles.image}
          src={featureIntroPages[page].image}
          alt={'feature introduction'}
          key={featureIntroPages[page].image}
        />
      </div>
    </Modal>
  );
}

export default FeatureIntroduction;
