import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useLocation } from 'react-router-dom';

import ActivitiesList from './ActivitiesList';
import ApiPath from '../../constants/api';
import analytics from '../../utils/analytics';
import apiCall from '../../utils/api';
import useConfigContext from '../../utils/useConfigContext';
import styles from './styles';
import { Grid, Snackbar, withStyles, Fade } from '@material-ui/core';
import { PAGES } from '../../constants/analytics';
import { activity as activityConstants } from './constants';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  TOAST_MSGS
} from '../../constants/common';
import ActivityTypeList from './ActivityTypeList';
import moment from 'moment';

const Activities = ({ classes }) => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const microSurveyCCMFeatureFlag =
    useConfigContext('enableMicroSurveys') === true;

  const location = useLocation();

  const [
    microSurveyPanelistLevelFeatureFlag,
    setMicroSurveyPanelistLevelFeatureFlag
  ] = useState(false);
  const [showWeeklyQuestFeatureIntro, setShowWeeklyQuestFeatureIntro] =
    useState(false);

  const [activityPreferenceInfo, setActivityPreferenceInfo] = useState([]);
  const [isTabFocused, setIsTabFocused] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () =>
      document.visibilityState === 'visible'
        ? setIsTabFocused(true)
        : setIsTabFocused(false);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (location?.pathname === '/activities') {
      loadMicroSurveyFeatureFlag();
    }
  }, [location?.pathname]);

  const loadMicroSurveyFeatureFlag = async () => {
    // check if the weekly quest feature intro has been completed locally
    // before an api call is made
    const hasLocalCompletion = Boolean(
      localStorage.getItem(`weekly-quests-feature-intro-completed`)
    );
    if (hasLocalCompletion) {
      setShowWeeklyQuestFeatureIntro(false);
    }
    apiCall(ApiPath.status, API.METHOD.GET, {}, false).then((response) => {
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        const { showMicroSurvey, metadataAttributes } = response?.data;
        setMicroSurveyPanelistLevelFeatureFlag(showMicroSurvey);
        const shouldShowWeeklyQuestFeatureIntro =
          metadataAttributes
            ?.find(
              (i) => i['detailName'] === 'micro_survey_feature_intro_complete'
            )
            ?.selectedList?.includes('false') ?? true;

        setShowWeeklyQuestFeatureIntro(shouldShowWeeklyQuestFeatureIntro);
      }
    });
  };
  const loadActivities = async () => {
    let loadedActivities = [];
    const { error, status, data } = await apiCall(
      ApiPath.availActivities,
      API.METHOD.POST,
      {},
      false
    );
    const {
      error: historyError,
      status: historyStatus,
      data: historyData
    } = await apiCall(
      ApiPath.activityHistoryListing,
      API.METHOD.POST,
      {},
      false
    );
    if (error || historyError) {
      setIsSnackbarOpen(true);
      setSnackbarMessage(error?.message || TOAST_MSGS.generic_error);
    }
    if (status === API_STATUS_CODE.SUCCESS && data?.data) {
      loadedActivities = data.data.filter(
        (x) =>
          x.type !== activityConstants.apiTypes.MICRO_SURVEY ||
          moment(x.timestamp).isAfter(moment().startOf('week'))
      );
    }
    if (historyStatus === API_STATUS_CODE.SUCCESS && historyData?.data) {
      loadedActivities.push(
        ...historyData.data.filter(
          (x) =>
            x.type === activityConstants.apiTypes.MICRO_SURVEY &&
            x.status === 'Completed' &&
            moment(x.timestamp).isAfter(moment().startOf('week'))
        )
      );
    }
    setActivities(loadedActivities);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isTabFocused) {
      setIsLoading(true);
      loadActivities();
      apiCall(
        `${ApiPath.profile}?type=activitypreference`,
        API.METHOD.GET,
        {},
        false
      ).then((response) => {
        if (response?.error) {
          setSnackbarMessage(
            response?.error?.message || TOAST_MSGS.generic_error
          );
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setActivityPreferenceInfo(
            response?.data?.profile?.[0]?.profileTypeDetailList
          );
        }
      });
    }

    analytics.trackPageView(PAGES.ACTIVITIES);
  }, [isTabFocused]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        message={snackbarMessage}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={() => setIsSnackbarOpen(false)}
        ContentProps={{
          classes: {
            root: classes.snackbarRoot
          }
        }}
      />
      <Fade in timeout={1000}>
        <Grid container className={classes.root}>
          <Switch>
            <Route path={`/activities/:categoryId`}>
              <ActivitiesList activities={activities} isLoading={isLoading} />
            </Route>
            <Route path="/activities">
              <ActivityTypeList
                activityPreferences={activityPreferenceInfo}
                availableActivities={activities}
                isWeeklyQuestFeatureFlagEnabled={
                  microSurveyCCMFeatureFlag &&
                  microSurveyPanelistLevelFeatureFlag
                }
                showWeeklyQuestFeatureIntro={showWeeklyQuestFeatureIntro}
                isLoading={isLoading}
              />
            </Route>
          </Switch>
        </Grid>
      </Fade>
    </>
  );
};

Activities.defaultProps = {
  classes: {}
};

Activities.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Activities);
