import React from 'react';
import styles from './styles.module.css';
import clsx from 'clsx';

type Props = {
  readonly totalPages: number;
  readonly currentPage: number;
};
function PaginationDots({ totalPages, currentPage }: Props) {
  return (
    <div className={styles.container}>
      {Array.from({ length: totalPages }, (_, i) => {
        const isActive = i === currentPage;
        return (
          <div
            className={clsx(styles.dot, isActive && styles.dotActive)}
            key={`page-${i}`}
          />
        );
      })}
    </div>
  );
}

export default PaginationDots;
